export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const OAUTH_URL = process.env.REACT_APP_OAUTH_URL;
export const API_KEY = process.env.REACT_APP_API_KEY;
export const SECRETE_KEY = process.env.REACT_APP_SECRETE_KEY;

export const generatePassword = () => {
    const chars = "0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const passwordLength = 12;
    let password = "";
    for (let i = 0; i <= passwordLength; i++) {
        const randomNumber = Math.floor(Math.random() * chars.length);
        password += chars.substring(randomNumber, randomNumber +1);
    }
    return password;
}

export const diets = [
    "Low-carbohydrate",
    "Keto",
    "Paleo",
    "Vegetarian",
    "Atkins",
    "Low-fat",
    "Gluten-free healthy",
    "Vegan",
    "Pescetarian",
    "Low sodium",
]
