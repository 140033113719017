import * as React from 'react';
import {useSelector} from 'react-redux';
import {useNavigate, Outlet} from "react-router-dom";
import Header from "./Header";
import Footer from "../Footer";

function Dashboard() {
    const {isAuthenticated} = useSelector(state => state.login);
    const navigate = useNavigate();

    React.useEffect(() => {
        if ( !isAuthenticated ) navigate("/");
    })

    return (
        <>
            <Header />
            <div className="container">
                <Outlet />
            </div>
            <Footer />
        </>
    );
}

export default Dashboard;
