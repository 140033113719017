import * as React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {logoutUser} from "../../reducers/loginSlice";

const Header = () => {
    const {isAuthenticated} = useSelector(state => state.login);
    const dispatch = useDispatch();
    return (
        <div className="container">
            <header
                className="d-flex flex-wrap align-items-center justify-content-center justify-content-md-between py-3 mb-4 border-bottom">
                <a href="/" className="d-flex align-items-center col-md-3 mb-2 mb-md-0 text-dark text-decoration-none">
                    <img src="img/suppermeal-logo.png" alt="Suppermeal logo" />
                </a>
                <div className="col-md-3 text-end">
                    {isAuthenticated ? (
                        <button type="button" className="btn btn-outline-primary me-2" onClick={() => {
                            dispatch(logoutUser());
                        }}>Logout</button>
                    ) : null}
                </div>
            </header>
        </div>
    );
}

export default Header;
