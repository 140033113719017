import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './store';
import Home from "./components/Home";
import reportWebVitals from './reportWebVitals';
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import ErrorLanding from "./components/Errors";
import Auth from "./components/Home/Auth";
import Dashboard from "./components/Dashboard";
import MealPlanLanding from "./components/Dashboard/MealPlanLanding";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Home />,
        errorElement: <ErrorLanding />
    },
    {
        path: "/account",
        element: <Auth />
    },
    {
        path: "/dashboard",
        element: <Dashboard />,
        children: [
            {
                path: "/dashboard",
                element: <MealPlanLanding />
            },
        ]
    }
]);
const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <RouterProvider router={router} />
        </Provider>
    </React.StrictMode>
);
reportWebVitals();
