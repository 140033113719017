import * as React from 'react';

const Footer = () => {
    return (
        <div className="container">
            <footer className="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top">
                <div className="col-md-4 d-flex align-items-center">
                    Powered by&nbsp;<a href="https://graxle.com/" rel="noreferrer" target="_blank" className="mb-3 me-2 mb-md-0 text-muted text-decoration-none lh-1">Graxle</a>
                </div>
            </footer>
        </div>
    );
}

export default Footer;
