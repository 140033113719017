import * as React from "react";
import {Field, Form} from "react-final-form";
import {Typeahead} from "react-bootstrap-typeahead";
import {diets} from "../../constants";
import Button from "react-bootstrap/Button";
import {useSelector} from "react-redux";
import {useCreateMealPlanMutation, useGetMealPlanByIDQuery} from "../../api/apiSlice";
import {stripHtml} from "string-strip-html";

const MealPlanForm = () => {
    const genders = ["male", "female"];
    const {token, user} = useSelector(state => state.login);
    const [createMealPlan, createMealPlanResult] = useCreateMealPlanMutation();

    const {
        data: mealPlan,
        isLoading,
        refetch
    } = useGetMealPlanByIDQuery({
        "token": token,
        "uuid": createMealPlanResult?.data?.uuid
    }, {
        skip: typeof createMealPlanResult?.data?.uuid === "undefined"
    });

    const submit = (values) => {
        let errors = 0;
        let diet = "";
        if (!values?.age || parseInt(values?.age) > 100 || parseInt(values?.age) < 1) errors += 1;

        if (!values?.height || parseInt(values?.height) > 8 || parseInt(values?.height) < 1) errors += 1;

        if (!values?.weight || parseInt(values?.weight) > 400 || parseInt(values?.weight) < 90) errors += 1;

        if (!values?.sex || !genders.includes(values?.sex)) errors += 1;

        if (values?.diet && values?.diet?.length > 0) {
            diet = values?.diet?.join(",");
        }


        if ( errors === 0 ) {
            createMealPlan({
                "token": token,
                "body": {
                    "age": values?.age ? values?.age : "",
                    "height": values?.height ? values?.height : "",
                    "weight": values?.weight ? values?.weight : "",
                    "diet": diet,
                    "sex": values?.sex ? values?.sex: ""
                },
            });
        }
    }

    const validate = (values) => {
        let errors = {};
        if (!values?.age || parseInt(values?.age) > 100 || parseInt(values?.age) < 1) {
            errors.age = "Please enter a valid age between 10 & 100 years.";
        }

        if (!values?.height || parseInt(values?.height) > 8 || parseInt(values?.height) < 1) {
            errors.height = "Please enter a valid height between 1 & 8' (feet).";
        }

        if (!values?.weight || parseInt(values?.weight) > 400 || parseInt(values?.weight) < 90) {
            errors.weight = "Please enter a valid weight. We can create meal plans for weight 90 - 400 lbs.";
        }

        if (!values?.sex || values?.sex === "gender") {
            errors.sex = "Please select a gender.";
        }

        return errors;
    }

    React.useEffect(() => {
        if (createMealPlanResult?.status === "fulfilled" && createMealPlanResult?.data?.hasOwnProperty("uuid")) {
            refetch();
        }
    }, [createMealPlanResult, refetch]);


    React.useEffect(() => {
        if (createMealPlanResult?.status === "fulfilled") {
            const interval = setInterval(() => {
                if (typeof mealPlan === "undefined" || mealPlan?.prompt === "_placeholder_pending_gpt3_process_completion") {
                    refetch();
                } else {
                    return () => clearInterval(interval);
                }
            }, 3000);
        }
    }, [createMealPlanResult, refetch, mealPlan]);

    return(
        <>
            {
                createMealPlanResult?.isLoading || isLoading || mealPlan?.prompt === "_placeholder_pending_gpt3_process_completion"
                ?
                (
                    <div className="p-2">
                        <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                        Generating meal plan...
                    </div>
                ): null
            }
            { typeof mealPlan !== "undefined" && mealPlan?.prompt !== "_placeholder_pending_gpt3_process_completion" ? (
                <div className="container">
                    <h1 className="h4">{user?.first_name}, here's your personalized meal plan</h1>
                    <div style={{whiteSpace: "pre-line"}}>{stripHtml(mealPlan?.prompt).result}</div>
                </div>
            ): (
                <>
                    <div className="py-5 pb-2 text-center">
                        <h2>About You</h2>
                        <p className="lead">
                            Let's answer a few questions about you. We'll use the answers to generate a meal plan personalized to fits your needs.
                        </p>
                    </div>
                    <div className="row g-5">
                        <Form
                            onSubmit={submit}
                            validate={validate}
                            render={( {handleSubmit, form} ) => {
                                return(
                                    <form onSubmit={handleSubmit}>
                                        <div className="card border-0">
                                            <div className="card-body">
                                                <Field
                                                    name="age"
                                                    render={({ input, meta }) => (
                                                        <div className="mt-4">
                                                            <label className="form-label fw-bold">How old are you?</label>
                                                            <input
                                                                {...input}
                                                                autoComplete="off"
                                                                className="form-control"
                                                                type="number"
                                                                placeholder="Age"
                                                                disabled={createMealPlanResult?.isLoading || isLoading || mealPlan?.prompt === "_placeholder_pending_gpt3_process_completion"}
                                                            />
                                                            {!createMealPlanResult?.isLoading ? (
                                                                meta.touched && meta.error && <span className="text-danger">{meta.error}</span>
                                                            ) : null}
                                                        </div>
                                                    )}
                                                />

                                                <Field
                                                    name="sex"
                                                    type="select"
                                                    render={({ input, meta }) => (
                                                        <div className="mt-4">
                                                            <label className="form-label fw-bold">What's your gender?</label>
                                                            <select
                                                                {...input}
                                                                className="form-select"
                                                                aria-label="Select a gender"
                                                                disabled={createMealPlanResult?.isLoading || isLoading || mealPlan?.prompt === "_placeholder_pending_gpt3_process_completion"}
                                                            >
                                                                <option value="gender">Choose a gender</option>
                                                                <option value="female">Female</option>
                                                                <option value="male">Male</option>
                                                            </select>
                                                            {!createMealPlanResult?.isLoading ? (
                                                                meta.touched && meta.error && <span className="text-danger">{meta.error}</span>
                                                            ) : null}
                                                        </div>
                                                    )}
                                                />


                                                <Field
                                                    name="height"
                                                    render={({ input, meta }) => (
                                                        <div className="mt-4">
                                                            <label className="form-label fw-bold">What is your approximate height in feet?</label>
                                                            <input {...input} type="range" min="3" max="9" step="0.1"
                                                                   onInput={() => {
                                                                       form.change("height", input.value);
                                                                   }}
                                                                   className="form-range" id="customRange1" />
                                                            <output>{input.value === "" ? '4': input.value}'</output>
                                                            {!createMealPlanResult?.isLoading ? (
                                                                meta.touched && meta.error && <span className="text-danger">{meta.error}</span>
                                                            ) : null}
                                                        </div>
                                                    )}
                                                />

                                                <Field
                                                    name="weight"
                                                    render={({ input, meta }) => (
                                                        <div className="mt-4">
                                                            <label className="form-label fw-bold">What is your weight in pounds (lbs)?</label>
                                                            <input
                                                                {...input}
                                                                autoComplete="off"
                                                                className="form-control"
                                                                type="number"
                                                                placeholder="Weight"
                                                                disabled={createMealPlanResult?.isLoading || isLoading || mealPlan?.prompt === "_placeholder_pending_gpt3_process_completion"}
                                                            />
                                                            {!createMealPlanResult?.isLoading ? (
                                                                meta.touched && meta.error && <span className="text-danger">{meta.error}</span>
                                                            ) : null}
                                                        </div>
                                                    )}
                                                />
                                            </div>
                                            <Field
                                                name="diet"
                                                render={({ input, meta }) => (
                                                    <div className="mt-4 ps-3 pe-3">
                                                        <label className="form-label fw-bold" htmlFor="diet_type">What is your preferred diet? (Optional)</label>
                                                        <Typeahead
                                                            id="diet"
                                                            labelKey="diet"
                                                            multiple={false}
                                                            allowNew={false}
                                                            onChange={(selected) => {
                                                                form.change("diet", selected);
                                                            }}
                                                            options={diets}
                                                            placeholder="Choose your preferred diet..."
                                                            disabled={createMealPlanResult?.isLoading || isLoading || mealPlan?.prompt === "_placeholder_pending_gpt3_process_completion"}
                                                        />
                                                        {!createMealPlanResult?.isLoading ? (
                                                            meta.touched && meta.error && <span className="text-danger">{meta.error}</span>
                                                        ) : null}

                                                    </div>
                                                )}
                                            />
                                            <div className="card-footer bg-white border-0 mt-4 p-4">
                                                <div className="float-end">
                                                    <Button
                                                        variant="primary"
                                                        size="lg"
                                                        disabled={createMealPlanResult?.isLoading || isLoading || mealPlan?.prompt === "_placeholder_pending_gpt3_process_completion"}
                                                        onClick={handleSubmit}
                                                    >
                                                        {
                                                            createMealPlanResult?.isLoading || isLoading || mealPlan?.prompt === "_placeholder_pending_gpt3_process_completion"
                                                                ?
                                                                (
                                                                    <div className="p-2">
                                                                        <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                                                        Generating meal plan...
                                                                    </div>
                                                                ): "Create Meal Plan"
                                                        }
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                )
                            }}
                        />

                    </div>
                </>
            )}
            {
                typeof createMealPlanResult?.error !== 'undefined' &&
                createMealPlanResult?.error?.data?.errors?.map((error, index) => {
                    return (
                        <div className="bg-warning p-4 mt-4" key={index}>
                            {error}
                        </div>
                    );
                })
            }
        </>
    )
}

export default MealPlanForm;
