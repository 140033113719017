import * as React from "react";
import {useSelector} from "react-redux";
import {useGetMealPlansQuery} from "../../api/apiSlice";
import MealPlanForm from "./MealPlanForm";
import MealPlan from "./MealPlan";

const MealPlanLanding = () => {
    const {token} = useSelector(state => state.login);

    const {
        data: mealPlans,
        isLoading
    } = useGetMealPlansQuery({
        "token": token
    });

    return (
        <main>
            {mealPlans?.length > 0 ? (
                <MealPlan mealPlans={mealPlans} />
            ): (
                !isLoading ? (
                    <>
                        <MealPlanForm />
                    </>
                ) : (
                    <p>Working...</p>
                )
            )}
        </main>
    );
}

export default MealPlanLanding;
