import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {API_KEY, BASE_URL} from '../constants';

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({baseUrl: BASE_URL}),
  endpoints: builder => ({
    signUp: builder.mutation({
      query: data => ({
        url: 'users_66laumsc/',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
        },
        body: {
          email: data.email ? data.email : '',
          first_name: data.first_name ? data.first_name : '',
          last_name: data.last_name ? data.last_name : '',
          password: data.password ? data.password : '',
          tokenize: true,
        },
      }),
    }),
    isValidUser: builder.mutation({
      query: data => ({
        url: 'users_66laumsc/resource_exists_by_value',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
        },
        body: {
          value: data.email ? data.email : '',
          label: 'email',
          tokenize: true,
        },
      }),
    }),
    createMealPlan: builder.mutation({
      query: data => ({
        url: "meal_plan_xlncq1dm/",
        method: typeof data?.values?.uuid === "undefined" ? "POST": "PUT",
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: 'Token ' + data?.token,
        },
        body: data?.body,
      }),
    }),
    getMealPlans: builder.query({
      query: data => ({
        url: 'meal_plan_xlncq1dm/?start=1&limit=100',
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: 'Token ' + data?.token,
        },
      }),
    }),
    getMealPlanByID: builder.query({
      query: data => ({
        url: 'meal_plan_xlncq1dm/' + data?.uuid,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: 'Token ' + data?.token,
        },
      }),
    }),
  }),
});

export const {
  useSignUpMutation,
  useIsValidUserMutation,
  useCreateMealPlanMutation,
  useGetMealPlansQuery,
  useGetMealPlanByIDQuery,
} = apiSlice;
