import * as React from 'react';
import {stripHtml} from "string-strip-html"
import {useSelector} from "react-redux";

const MealPlan = ({mealPlans}) => {
    const plan = stripHtml(mealPlans[0]?.prompt).result;
    const {user} = useSelector(state => state.login);
    return (
        <div className="container">
            {
                mealPlans[0]?.prompt === "_placeholder_pending_gpt3_process_completion" ?
                (
                    <div className="p-2">
                        <h1 className="h4">{user?.first_name}, we're still personalizing your plan. Grab your fav cup of coffee and come back.</h1>
                    </div>
                ):
                (
                    <>
                        <h1 className="h4">{user?.first_name}, here's your personalized meal plan</h1>
                        <div style={{whiteSpace: "pre-line"}}>{plan}</div>
                    </>
                )
            }
        </div>
    );
}

export default MealPlan;
