import * as React from 'react';
import {useSelector} from "react-redux";
import Footer from "../Footer";

const Home = () => {
    const {isAuthenticated} = useSelector(state => state.login);
    return (
        <>
            <div className="container">
                <header className="d-flex flex-wrap justify-content-center py-3 mb-4">
                    <a href="/"
                       className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-dark text-decoration-none">
                        <img src="img/suppermeal-logo.png" alt="Suppermeal logo"/>
                    </a>

                    <ul className="nav nav-pills">
                        <li className="nav-item"><button className="btn btn-link text-muted text-decoration-none" aria-current="page">Home</button>
                        </li>
                        {isAuthenticated ? (
                            <li className="nav-item"><a href="/dashboard" className="btn btn-link text-muted text-decoration-none">My Meal Plan</a></li>
                        ) : <li className="nav-item"><a href="/account?cta=login" className="btn btn-link text-muted text-decoration-none">Login</a></li>}
                    </ul>
                </header>
            </div>
            <div className="container col-xxl-8 px-4 py-5">
                <div className="row flex-lg-row-reverse align-items-center g-5 py-5">
                    <div className="col-10 col-sm-8 col-lg-6">
                        <img src="img/healthy-meal-illustrated.png" className="d-block mx-lg-auto img-fluid" alt="Bootstrap Themes"
                             width="700" height="500" loading="lazy" />
                    </div>
                    <div className="col-lg-6">
                        <h1 className="display-5 fw-bold lh-1 mb-3">AI generated meal plan, personalized for you</h1>
                        <p className="lead">Tell us a little about yourself and your plans to eat healthier and we'll create a hyper personalized meal plan complete with recipes and a shopping list designed to help you eat better and realize your health goals.</p>
                        <div className="d-grid gap-2 d-md-flex justify-content-md-start">


                            {isAuthenticated ? (
                                <a href="/dashboard" className="btn btn-primary btn-lg px-4 me-md-2">My Meal Plan</a>
                            ) : <a href="/account?cta=signup" className="btn btn-primary btn-lg px-4 me-md-2">Get started. It's free</a>}

                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default Home;
