import { useRouteError } from "react-router-dom";

const ErrorLanding = () => {
    const error = useRouteError();

    return (
        <div id="container">
            <div className="px-4 py-5 my-5 text-center">
                    <div className="col-lg-6 mx-auto">
                        <h1>Oops!</h1>
                        <p>Something went wrong!</p>
                        <p>
                            <i>{error.statusText || error.message}</i>
                        </p>
                        <div className="d-grid gap-2 d-sm-flex justify-content-sm-center">
                            <a href="/" className="btn btn-outline-secondary btn-lg px-4">Go home</a>
                        </div>
                    </div>
            </div>
        </div>
    );
};

export default ErrorLanding;
