import {configureStore} from '@reduxjs/toolkit';
// import collectionReducer from './reducers/collectionSlice';
import appReducer from './reducers/appSlice';
import loginReducer from './reducers/loginSlice';
// import errorReducer from './reducers/errorSlice';
import {apiSlice} from './api/apiSlice';

export const store = configureStore({
  reducer: {
      login: loginReducer,
      app: appReducer,
      // error: errorReducer,
      // collections: collectionReducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
  },
  middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        thunk: true,
        immutableCheck: true,
        serializableCheck: false,
      }).concat(apiSlice.middleware),
});
